import styles from '../styles/GuessList.module.scss'
import GuessItem from "./GuessItem";

export default function GuessList({guesses, showEmoji}) {
    if (guesses.length > 0) {
        return (
            <div className={styles["guess-results"]}>
                {guesses.map((guess, i) => {
                    return (<GuessItem key={i}
                                       showEmoji={showEmoji}
                                       value={guess.value}
                                       state={{name: guess.state, country: guess.countryState}}/>)
                })}
            </div>
        )
    }
}


