import styles from '../styles/Chip.module.scss'

export default function Chip({ value, type }) {

  const getClassNames = () => {
    return `chip-${type}`
  }

  return (
      <span className={`ant-tag ${styles.chip} ${styles[getClassNames()]}`}>{value}</span>
  )
}