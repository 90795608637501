import styles from "@styles/Home.module.scss";
import Chip from "@components/Chip";

import top_songs from "@assets/artist_data/small_artist_list_filtered_top_songs.json";
import artists from "@assets/artist_data/small_artist_list_filtered_with_ids.json";
import search_options from "@assets/artist_data/small_artist_list_filtered_search_options.json";
import continent_data from "@assets/continent.json";
import game_list from "@assets/games.json";
import { Button, Modal } from "antd";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import GuessList from "@components/GuessList";
import ShareResult from "@components/ShareResult";
import GuessItem from "@components/GuessItem";
import {
  FlagFilled,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Artist } from "@models/artist";
import { CountryState, Guess } from "@models/guess";
import { GameState, SavedGame, State } from "@models/state";
import { getTimezoneOffset } from "date-fns-tz";
import { Song } from "@models/song";
import { canShowFlagEmoji, getNeighbors } from "@shared/utils";
import { useLanguageQuery, useTranslation } from "next-export-i18n";
import Head from "next/head";
import ReactGA from "react-ga4";
import Flag from "@components/Flag";

export default function Home() {
  const showEmoji = canShowFlagEmoji();
  const { t } = useTranslation();
  const [query] = useLanguageQuery();
  const isDev = process.env.NODE_ENV === "development";

  const game_reset_time = useMemo(() => [23, 59, 59], []);

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["name"]
  };
  const start_date = "2022-07-22";
  const MAX_GUESS = 8;
  const obfuscate_regex = /[a-z\dáéíóúàèìòùÁÉÍÓÚ&?¿+-ñÑ!¡*]/gi;

  const getGameId = useCallback((): number => {
    const initDate = start_date.split("-").map(d => parseInt(d));
    const initDateUTC = Date.UTC(initDate[0], initDate[1] - 1, initDate[2] - 1, game_reset_time[0] - 2, game_reset_time[1] + 1, 0, 0);
    const generatedId = Math.floor((Date.now() - initDateUTC) / 8.64e7);

    return Math.max(0, generatedId);
  }, [game_reset_time]);

  const getSelectedArtistId: (n: number) => string = (gameId: number): string => {
    return game_list[gameId];
  };

  const getTimeUntilNextGame = useCallback((): number => {
    const timeZoneOffset = getTimezoneOffset("Europe/Madrid");
    const utc_timed = new Date(new Date().getTime() + timeZoneOffset);
    return 3600 * (game_reset_time[0] - utc_timed.getUTCHours()) + 60 * (game_reset_time[1] - utc_timed.getUTCMinutes()) + (game_reset_time[2] - utc_timed.getUTCSeconds());
  }, [game_reset_time]);

  const initialState: State = {
    current_game_id: -1,
    selected_artist: null,
    loaded: false,
    game: {
      songs: [],
      country_info: {
        neighbors: [], checked_countries: []
      },
      genres: [],
      found_genres: [],
      uncovered_songs: [],
      search_options: search_options,
      guesses: [],
      found_artists: [],
      state: "playing"
    },
    search: "",
    showHelp: false,
    showInfo: false,
    showEndGame: false,
    firstTime: true,
    timerEnabled: false,
    nextGame: getTimeUntilNextGame()
  };

  const initializeGameData = useCallback((current_game_id: number, selected_artist: Artist): any => {
    if (typeof window !== "undefined") {
      const games = JSON.parse(localStorage.getItem("games"));
      if (games === null) {
        localStorage.setItem("userFirstTime", "1");
        localStorage.setItem("games", JSON.stringify([]));
      } else {
        const currentGame: SavedGame = games.find(game => game.id === current_game_id);

        if (currentGame) {
          const found_artists = currentGame.guesses
            .filter(g => g.state === "collab_guess")
            .map(g => g.value.name);
          const found_genres = Array.from(new Set(currentGame.guesses.map(g => artists.find(a => a.name === g.value.name).genres).flat()));
          const already_guessed = currentGame.guesses.map(g => g.value);

          const guesses = [...currentGame.guesses.map((o => ({ ...o })))];
          let uncovered_songs = [];
          if (guesses.length > 2) {
            uncovered_songs = guesses
              .splice(0, currentGame.guesses.length - 2)
              .map((g, i) => i);
          }

          const firstTime = localStorage.getItem("userFirstTime") === "1";
          localStorage.setItem("userFirstTime", "0");

          return {
            showHelp: firstTime, game: {
              guesses: currentGame.guesses,
              state: currentGame.state,
              country_info: {
                neighbors: currentGame.saved_neighbors || getNeighbors(selected_artist.country_code),
                checked_countries: Array.from(new Set(currentGame.guesses.map(g => g.value.country)))
              },
              found_genres: found_genres,
              found_artists: found_artists,
              uncovered_songs: uncovered_songs,
              search_options: search_options
                .filter(option => !already_guessed.map(g => g.name).includes(option.name))
            }
          };
        }

        games.push({
          state: "playing",
          guesses: [],
          id: current_game_id,
          saved_neighbors: getNeighbors(selected_artist.country_code)
        });
        localStorage.setItem("games", JSON.stringify(games));
      }
      return {
        showHelp: localStorage.getItem("userFirstTime") === "1", game: {
          country_info: {
            neighbors: getNeighbors(selected_artist.country_code), checked_countries: []
          }
        }
      };
    }
    return {};

  }, []);

  function initState(state) {
    const current_game_id = getGameId();
    const selected_artist_id = getSelectedArtistId(current_game_id);
    const selected_artist: Artist = artists.find(a => a.id === selected_artist_id);

    const songs = top_songs[selected_artist_id].map(t => {
      t.released_on = parseInt(t.released_on);
      return t;
    }).sort((t1, t2) => {
      return t1.released_on - t2.released_on || t1.title.localeCompare(t2.title);
    });

    return {
      ...state, current_game_id: current_game_id, selected_artist: selected_artist, game: {
        ...state.game, songs: songs, genres: [...selected_artist.genres]
      }
    };
  }

  const [state, dispatch] = useReducer((state: State, updates: Partial<State>) => ({
    ...state, ...updates
  }), initialState, initState);

  const isEndGame = useCallback(() => {
    return ["win", "lose"].includes(state.game.state);
  }, [state.game.state]);

  const showHelp = useCallback(() => {
    dispatch({ showHelp: true });
  }, []);

  const showInfo = () => {
    dispatch({ showInfo: true });
  };

  const storeData = useCallback((current_data: GameState) => {
    if (typeof window !== "undefined") {
      const games = JSON.parse(localStorage.getItem("games"));

      const current_game_index = games !== undefined ? games.findIndex(game => game.id === state.current_game_id) : -1;

      if (current_game_index !== -1) {
        games[current_game_index]["saved_neighbors"] = current_data.country_info.neighbors;
        games[current_game_index]["guesses"] = current_data.guesses;
        games[current_game_index]["state"] = current_data.state;

        localStorage.setItem("games", JSON.stringify(games));
      }
    }
  }, [state.current_game_id]);

  useEffect(() => {
    if (!state.loaded) {
      const loadedData = initializeGameData(state.current_game_id, state.selected_artist);

      let newState = {
        ...state, loaded: true, showHelp: loadedData.showHelp, game: {
          ...state.game, ...loadedData.game
        }
      };
      dispatch(newState);
    } else {
      if (isEndGame() && state.nextGame <= 0 && state.current_game_id !== getGameId()) {
        location.reload();
      }

      if (state.game && state.loaded) {
        if (isEndGame() && state.firstTime) {
          const interval: number = window.setInterval(() => {
            dispatch({ nextGame: getTimeUntilNextGame() });
          }, 1e3);

          dispatch({
            firstTime: false, showEndGame: true, nextGame: getTimeUntilNextGame(), timerEnabled: interval
          });
        } else if (state.game.state === "playing" && state.game.guesses.length >= MAX_GUESS) {
          ReactGA.event({
            category: "Game finished", action: "lose_game"
          });

          const newState: Partial<State> = {
            game: {
              ...state.game, state: "lose"
            }
          };
          dispatch(newState);
        }
        storeData(state.game);
      }

      if (state.timerEnabled !== false && !state.showEndGame) {
        clearInterval(state.timerEnabled as number);
      }
    }
  }, [state, state.loaded, getTimeUntilNextGame, storeData, isEndGame, getGameId, initializeGameData]);

  const handleOk = () => {
    dispatch({
      showHelp: false, showInfo: false, showEndGame: false
    });
  };

  const handleCancel = () => {
    dispatch({
      showHelp: false, showInfo: false, showEndGame: false
    });
  };

  const getCountryState = (artist: Artist): CountryState => {
    if (artist.country_code === state.selected_artist.country_code) {
      return "correct_guess";
    }

    if (continent_data[artist.country_code] === continent_data[state.selected_artist.country_code]) {
      return "collab_guess";
    }

    return "bad_guess";
  };

  const getUncoveredSongs = (newGuesses): number[] => {
    if (newGuesses > 2) {
      return [...state.game.uncovered_songs, state.game.uncovered_songs.length];
    }
    return state.game.uncovered_songs;
  };

  const wrongGuess = (guess: Artist) => {
    const genre_guess = guess.genres.some(g => state.game.genres.includes(g));

    const newGuesses: Guess[] = [...state.game.guesses.map(o => ({ ...o })), {
      value: { name: guess.name, country: guess.country_code },
      state: genre_guess ? "genre_guess" : "bad_guess",
      countryState: getCountryState(guess)
    }];

    dispatch({
      game: {
        ...state.game,
        guesses: newGuesses,
        country_info: {
          ...state.game.country_info,
          checked_countries: Array.from(new Set([...state.game.country_info.checked_countries, guess.country_code]))
        },
        uncovered_songs: getUncoveredSongs(newGuesses.length),
        found_genres: Array.from(new Set([...state.game.found_genres, ...guess.genres])),
        search_options: state.game.search_options.filter(option => option.name !== guess.name)
      }, search: ""
    });
  };

  const correctGuess = (guess: Artist) => {
    ReactGA.event({
      category: "Game finished", action: "win_game"
    });

    dispatch({
      game: {
        ...state.game, guesses: [...state.game.guesses.map(o => ({ ...o })), {
          value: { name: guess.name, country: guess.country_code },
          state: "correct_guess",
          countryState: "correct_guess"
        }], state: "win"
      }, search: "", nextGame: getTimeUntilNextGame()
    });
  };

  const uncoverCollaborator = (collaborator: Artist) => {
    dispatch({
      game: {
        ...state.game,
        guesses: [...state.game.guesses.map(o => ({ ...o })), {
          value: { name: collaborator.name, country: collaborator.country_code },
          state: "collab_guess",
          countryState: getCountryState(collaborator)
        }],
        country_info: {
          ...state.game.country_info,
          checked_countries: Array.from(new Set([...state.game.country_info.checked_countries, collaborator.country_code]))
        },
        found_genres: Array.from(new Set([...state.game.found_genres, ...collaborator.genres])),
        found_artists: [...state.game.found_artists, collaborator.name],
        search_options: state.game.search_options.filter(option => option.name !== collaborator.name)
      }, search: ""
    });
  };

  const handleOnSearch = (query: string, _) => {
    dispatch({ search: query });
  };

  const handleOnHover = (_) => {
    // the item hovered
  };

  const handleOnSelect = ({ name }) => {
    const selected = artists.find(a => a.name === name);
    if (artists.find(a => a.id === state.selected_artist.id).name === name) {
      correctGuess(selected);
    } else {
      const collaborator_songs = state.game.songs.filter(s => s.artists.includes(name));
      if (collaborator_songs.length > 0) {
        uncoverCollaborator(selected);
      } else {
        wrongGuess(selected);
      }
    }
  };


  const handleOnFocus = () => {
  };

  const formatResult = (item) => {
    return (<div className={styles["search-result"]}>
      <span>{item.name}</span>
    </div>);
  };

  const obfuscate = (s: string): string => {
    return s.replace(obfuscate_regex, "X");
  };

  const getArtists = (artists) => {
    if (artists.length > 0) {

      if (isEndGame()) {
        return `${artists.join(", ")}`;
      } else {
        let artists_str = "";
        const found = artists.filter(a => state.game.found_artists.includes(a));

        if (found.length > 0) {
          return `${artists.join(", ")}`;
        }

        const not_found = artists.filter(a => !found.includes(a));
        if (not_found.length > 0) {
          if (not_found.length > 4) {
            artists_str += `${not_found.map(obfuscate).slice(0, 4).join(", ")}... +${not_found.length - 4}`;
          } else {
            artists_str += `${not_found.map(obfuscate).join(", ")}`;
          }
        }

        return artists_str;
      }
    }

    return (<Chip value={"Solo"} type={"artist"} />);
  };

  const getRowClassName = (artists) => {
    if (["playing", "lose"].includes(state.game.state)) {
      return `${styles[artists.some(a => state.game.found_artists.includes(a)) ? "collab" : "clear"]}`;
    }

    if (state.game.state === "win") {
      return styles["win"];
    }
  };

  const isSongFound = (song) => {
    return state.game.state === "win" || song.artists.some(a => state.game.found_artists.includes(a));
  };

  const getTitle = (song, index) => {
    if (isSongFound(song) || state.game.state === "lose" || state.game.uncovered_songs.includes(index)) {
      if (state.game.state === "playing" && song.title.includes(state.selected_artist.name)) {
        return song.title.replace(state.selected_artist.name, obfuscate(state.selected_artist.name))
      }
      return song.title;
    }

    return obfuscate(song.title);
  };

  const isGenreFound = (genre: string): boolean => {
    return state.game.state === "win" || state.game.found_genres.includes(genre);
  };

  interface Genre {
    value: string;
    type: string;
  }

  const getGenre = (genre: string): Genre => {
    let value = genre;
    let type = "genre";

    if (!(isGenreFound(genre) || state.game.state === "lose")) {
      value = obfuscate(genre);
      type = "genre-hidden";
    }

    return {
      value, type
    };
  };

  const resetGameState = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("games", null);
      location.reload();
    }
  };

  const getCountryInfo = () => {
    const correct_country_code = state.selected_artist.country_code;
    if (isEndGame() || state.game.country_info.checked_countries.includes(correct_country_code)) {
      return <>= <Flag country_code={correct_country_code} showEmoji={showEmoji} /></>;
    } else if (state.game.country_info.checked_countries.some(c => continent_data[c] === continent_data[correct_country_code])) {
      const close_guesses = state.game.country_info.neighbors
        .filter(c => !state.game.country_info.checked_countries.includes(c));

      const flags = Array.from(new Set([...close_guesses, correct_country_code].sort()))
        .map((cc, i) => (<Flag key={i} country_code={cc} showEmoji={showEmoji} />));
      return <><span className={styles["multiflags"]}>in {flags}</span></>;
    }

    return <>= <QuestionOutlined /></>;
  };

  const formatCountdownTime = () => {

    if (state.nextGame > 0) {
      const hours = Math.floor(state.nextGame / 3600);
      const minutes = Math.floor((state.nextGame - 3600 * hours) / 60);
      const seconds = Math.floor(state.nextGame % 60);

      return <span>{("00" + hours).slice(-2)}:{("00" + minutes).slice(-2)}:{("00" + seconds).slice(-2)}</span>;
    }

    return <span>AVAILABLE NOW!</span>;
  };

  const artist_i18n = t("artist");

  return (<>
    <Head>
      <title>{t("title")}</title>
      <meta name="title" content={t("title")} />
      <meta property="og:title" content={t("title")} />
      <meta property="twitter:title" content={t("title")} />

    </Head>
    <header className={styles.header}>
      <InfoCircleOutlined onClick={showInfo} />
      <h1>Artistle</h1>
      <QuestionCircleOutlined onClick={showHelp} />
    </header>

    <Modal visible={state.showEndGame} footer={null} onOk={handleOk} onCancel={handleCancel}>
      <div className={styles["modal-container"]}>
        {state.game.state === "win" && <h2>{t("end-congrats", {
          guesses: state.game.guesses.length,
          guess_word: state.game.guesses.length === 1 ? t("guess-singular") : t("guess-plural")
        })}</h2>}
        {state.game.state === "lose" && <h2>{t("end-oops")}:</h2>}
        <ShareResult
          artist={state.selected_artist.name}
          guesses={state.game.guesses}
          gameId={state.current_game_id}
          maxGuess={MAX_GUESS}
          state={state.game.state}
        />
        <h3>{t("new-artist")} {formatCountdownTime()}</h3>
      </div>
    </Modal>

    <Modal visible={state.showInfo} footer={null} onOk={handleOk} onCancel={handleCancel}>
      <div className={styles["modal-container"]}>
        <h1>Artistle</h1>
        <h3>{t("subtitle")}.</h3>

        <p>{t("info-1")}</p>
        <p>{t("info-2")}</p>
        <p>{t("info-3")} <a target={"_blank"} rel={"noreferrer"}
                            href={"https://twitter.com/bonet_4"}>@bonet_4</a>. {t("info-4")}</p>
      </div>
    </Modal>

    <Modal visible={state.showHelp}
           title={t("how-to")}
           footer={[<Button key="back" type="primary" onClick={handleCancel}>{t("play")}</Button>]}
           onOk={handleOk}
           onCancel={handleCancel}>
      <div className={styles["help-modal-container"]}>
        <p>{t("how-to-1")}</p>
        <p>{t("how-to-2")}</p>
        <p>{t("how-to-3")}</p>
        <p>{t("how-to-examples")}:</p>

        <div className={styles["help-modal-examples"]}>
          <div>

            <p>1: <span className={styles["match"]}>{t("incorrect")}:</span> {t("incorrect-exp")}.</p>
            <GuessItem value={{ name: `${artist_i18n} 1`, country: "US" }}
                       state={{ name: "bad_guess", country: "bad_guess" }}
                       showEmoji={showEmoji} />
          </div>
          <div>
            <p>2: <span className={styles["match"]}>{t("genre-match")}:</span> {t("genre-match-exp")}.</p>
            <GuessItem value={{ name: `${artist_i18n} 2`, country: "AU" }}
                       state={{ name: "genre_guess", country: "bad_guess" }}
                       showEmoji={showEmoji} />

          </div>
          <div>
            <p>3: <span className={styles["match"]}>{t("collab-match")}:</span> {t("collab-match-exp")}</p>
            <GuessItem value={{ name: `${artist_i18n} 3`, country: "FR" }}
                       state={{ name: "collab_guess", country: "collab_guess" }}
                       showEmoji={showEmoji} />
          </div>
          <div>
            <p>4: <span className={styles["match"]}>{t("correct")}.</span> {t("correct-exp")}</p>
            <GuessItem value={{ name: `${artist_i18n} 4`, country: "ES" }}
                       state={{ name: "correct_guess", country: "correct_guess" }}
                       showEmoji={showEmoji} />
          </div>
        </div>
      </div>
    </Modal>

    <div className={styles.container}>
      {state.loaded && <main className={styles.main}>
        {isDev && <button type="button" onClick={resetGameState}
                          className="ant-btn ant-btn-round ant-btn-primary ant-btn-lg css-1r4vku6">
          <span>Reset game state</span>
        </button>}

        <div className={styles.game}>
          {isEndGame() && <ShareResult artist={state.selected_artist.name} guesses={state.game.guesses}
                                       gameId={state.current_game_id}
                                       maxGuess={MAX_GUESS}
                                       state={state.game.state}
          />}

          {state.game.state === "playing" && <div className={styles["search-container"]}>
            <ReactSearchAutocomplete
              fuseOptions={fuseOptions}
              inputSearchString={state.search}
              placeholder={`${t("guess-n", { count: state.game.guesses.length + 1, max: MAX_GUESS })}`}
              items={state.game.search_options}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
            />

            {state.game.guesses.length === 0 && <div className={styles["search-hint"]}>
                    <span>
                      <SearchOutlined />&nbsp;
                      <span>
                        {t("search-hint.head")}&nbsp;
                        <span className={styles["hint"]}>{t("search-hint.bold")}</span>&nbsp;
                        {t("search-hint.tail")}
                      </span>
                    </span>
            </div>}
          </div>}

          <div className={styles["flags-container"]}>
            <FlagFilled /> {getCountryInfo()}
          </div>

          <div className={styles["guessList-container"]}>
            <GuessList guesses={state.game.guesses} showEmoji={showEmoji} />
          </div>

          <div className={styles["genres-container"]}>
            {state.game.genres.map((g, i) => {
              const genre = getGenre(g);

              return <Chip key={i} value={genre.value} type={genre.type} />;
            })}
          </div>

          <div className={styles.table_container}>
            <table className={styles.clues} cellSpacing={"0"} cellPadding={"0"}>
              <thead>
              <tr>
                <th align="left">{t("track")}</th>
                <th align="left">{t("other-artists")}</th>
              </tr>
              </thead>
              <tbody>
              {state.game.songs.map((d: Song, i: number) => {
                return <tr
                  key={i}
                  className={getRowClassName(d.artists)}>
                  <td>
                    <div
                      className={`${styles.title_cell} ${isSongFound(d) ? "" : styles["text-gray"]}`}>{getTitle(d, i)}</div>
                    <div className={`${styles["text-gray"]}`}>{d.released_on}</div>
                  </td>
                  <td>{getArtists(d.artists)}</td>
                </tr>;
              })}
              </tbody>
            </table>
          </div>
        </div>
      </main>}
    </div>
    <footer className={styles.footer}>
      <span>Artistle &copy; 2022 | <a href={"mailto:artistle@jbonet.xyz"}>artistle@jbonet.xyz</a></span>
    </footer>
  </>);
}
