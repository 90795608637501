import country_neighbors from "../assets/country_neighbors.json"
import continent_data from "../assets/continent.json"

export const iso2FlagEmoji = (iso: string): string => {
    return String.fromCodePoint(...iso.split("").map(char => char.charCodeAt(0) + 127397));
}

export const canShowFlagEmoji = () => {
  if (typeof window !== "undefined") {
    const ctx = document.createElement("canvas").getContext("2d");
    ctx.canvas.width = ctx.canvas.height = 1;
    ctx.fillText("🇪🇸", -4, 4);
    return ctx.getImageData(0, 0, 1, 1).data[0] > 0;
  }
}

export const getNeighbors = (iso: string): string[] => {
  let neighbors = country_neighbors[iso];

  if (neighbors.length < 5) {
    const extra = Object
      .entries(continent_data)
      .filter(([key, value]) => value === continent_data[iso] && key !== iso && !neighbors.includes(key))
      .map(([key, _]) => key);

    neighbors = [...neighbors, ...getRandom(extra, 5 - neighbors.length)];
  }

  return neighbors;
};

export function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function getRandom(arr, n) {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}