const en = require("./locales/en/app.json");
const es = require("./locales/es/app.json");

const i18n = {
  translations: {
    en, es
  }, defaultLang: "en", useBrowserDefault: true
};

module.exports = i18n;
