import styles from "../styles/GuessItem.module.scss";
import Flag from "@components/Flag";

export default function GuessItem({ state, value, showEmoji }) {

  const getStateClassName = (state) => {
    switch (state) {
      case "bad_guess":
        return "bad";

      case "correct_guess":
        return "correct";

      case "collab_guess":
        return "collab";

      case "genre_guess":
        return "genre";

      default:
        return "bad";
    }
  };

  return (
    <div className={styles["guess-item"]}>
      <div className={`${styles["guess-item-name"]} ${styles[getStateClassName(state.name)]}`}>{value.name}</div>
      <span className={`${styles["guess-item-flag"]} ${styles[getStateClassName(state.country)]}`}>
        <Flag country_code={value.country} showEmoji={showEmoji} />
      </span>
    </div>
  );
}