import { iso2FlagEmoji } from "@shared/utils";
import Image from "next/future/image";

export default function Flag({ country_code, showEmoji }) {
  if (showEmoji) {
    return <>{iso2FlagEmoji(country_code)}</>;
  } else {
    return <Image alt={country_code} width={30} height={20}
                  src={`/images/flags/4x3/${country_code.toLowerCase()}.svg`} />;
  }
}