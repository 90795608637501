import styles from "@styles/ShareResult.module.scss";

import { Button, notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification";
import { ShareAltOutlined, TwitterOutlined } from "@ant-design/icons";
import { useTranslation } from "next-export-i18n";
import ReactGA from "react-ga4";

export default function ShareResult({ artist, guesses, gameId, maxGuess, state }) {
  const { t } = useTranslation("app");

  const getEmoji = () => {
    if (guesses.length === maxGuess) {
      return "😔";
    } else if (guesses.length < maxGuess && guesses.length >= 5) {
      return "😃";
    } else {
      return "😁";
    }
  };

  const getSymbol = () => {
    if (state === "win") {
      return "🎉";
    }

    return "❌";
  };

  const openNotification = (placement: NotificationPlacement) => {
    notification["success"]({
      message: t("copy-clip"), placement
    });
  };


  const buildGuesses = () => {
    const results = [...guesses.map(g => {

      if (g.state === "correct_guess") {
        return "🟩";
      } else if (["collab_guess", "genre_guess"].includes(g.state) || (g.state === "bad_guess" && g.countryState === "collab_guess")) {
        return "🟨";
      } else if (g.state === "bad_guess") {
        return "🟥";
      }
    }), ..."⬛".repeat(maxGuess - guesses.length).split("")];

    return `#Artistle #${gameId + 1} \n\n${getEmoji()}${results.join("")} ${getSymbol()} ${guesses.length}/${maxGuess}\n\nhttps://artistle.jbonet.xyz`;
  };

  const shareCopy = () => {
    ReactGA.event({
      category: "Results Screen",
      action: "share"
    });
    const text = buildGuesses();
    const data = {
      text: text
    };
    if (typeof window !== "undefined") {
      if (window.navigator["share"] && window.navigator.canShare(data)) {
        navigator
          .share(data)
          .then(() => {
            console.log("Successfully shared");
          })
          .catch((error) => {
            console.error("Something went wrong", error);
          });
      } else {
        window.navigator.clipboard.writeText(text);
        openNotification("top");
      }
    }
  };

  const openInNewTab = url => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const shareOnTwitter = () => {
    ReactGA.event({
      category: "Results Screen",
      action: "share-tw"
    });
    const shareString = buildGuesses();
    openInNewTab(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareString)}`);
  };

  return (<>
    <h1 className={styles.result}>{artist}</h1>
    <div className={styles["button-container"]}>
      <Button className={styles["alt-button"]} onClick={shareCopy} type="primary" shape="round" size="large"
              icon={<ShareAltOutlined />}>
        {t("share")}
      </Button>
      <Button onClick={shareOnTwitter} type="primary" shape="round" size="large" icon={<TwitterOutlined />}>
        {t("share-tw")}
      </Button>
    </div>
  </>);
}

